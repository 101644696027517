import { xxTest } from "./api";

async function getNummerInfos(rufnummer: string) {
  const xx = await xxTest().get(`/telefon/getNummerInfos/${rufnummer}`);
  const json = xx.data;
  return json;
}

async function getCallHistory() {
  const xx = await xxTest().get(`/telefon/getCallHistory/`);
  const json = xx.data;
  return json;
}

async function isOftAngerufen(rufnummer: string) {
  const xx = await xxTest().post(`/telefon/isOftAngerufen/`, {
    nummer: rufnummer,
  });
  const json = xx.data;
  return json;
}

async function isGesperrteRufnummer(rufnummer: string) {
  const xx = await xxTest().post(`/telefon/gesperrt/`, { nummer: rufnummer });
  const json = xx.data;
  return json;
}

async function insertAnruf(data: any) {
  const xx = await xxTest().post(`/telefon/insertAnruf`, data);
  const json = xx.data;
  return json;
}
async function updateAnrufNotiz(note: string, anrufId: number) {
  const data = { notiz: note, id: anrufId };
  const xx = await xxTest().post(`/telefon/updateNotiz`, data);
  const json = xx.data;
  return json;
}

async function updateAnrufDauer(id: number | string, interval: string) {
  const data = { id, interval };
  const xx = await xxTest().post(`/telefon/updateDauer`, data);
  const json = xx.data;
  return json;
}

export {
  isGesperrteRufnummer,
  getCallHistory,
  getNummerInfos,
  isOftAngerufen,
  insertAnruf,
  updateAnrufNotiz,
  updateAnrufDauer,
};
