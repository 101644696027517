import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Timer } from "timer-node";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReactDOM from "react-dom";
import {
  Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions,
  Web,
  RegistererState,
  Ack,
  Bye,
  SessionInviteOptions,
  RequestPendingError,
} from "sip.js";
import useAuth from "../../hooks/useAuth";
import CallIcons from "./CallIcons";
import { xxTest } from "../../_apis/api";
import {
  getCallHistory,
  getNummerInfos,
  insertAnruf,
  isGesperrteRufnummer,
  isOftAngerufen,
  updateAnrufDauer,
  updateAnrufNotiz,
} from "../../_apis/telefon";
import { toast } from "react-toastify";
import {
  Close,
  DragIndicator,
  LocalPhone,
  MicOffRounded,
  Mic,
  PhoneCallback,
  PhoneForwardedRounded,
  PhoneMissed,
  PhonePaused,
} from "@mui/icons-material";
import React from "react";
import { VsIndZeit } from "../visiqsCustomStuff/ProfilIcons";
import AnrufNotiz from "../AnrufNotiz";
import { createTicket } from "../../_apis/support";
import "bootstrap/dist/css/bootstrap.min.css";
import { randomId } from "@mui/x-data-grid-generator";
import { yanduuFormatNummer } from "../visiqsCustomStuff/VisHelper";

const NeuTelefon = () => {
  const { user } = useAuth();
  const timer = new Timer({ label: "dauer" });

  let ajuid = -66;
  let ajunr = "+49123 123456766";
  let interval: string | number | NodeJS.Timeout | null | undefined = null;

  const PNF = require("google-libphonenumber").PhoneNumberFormat;
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const [anrufId, setAnrufId] = React.useState<any>(0);
  const [intervalId, setintervalId] = React.useState<any>();

  const [inCall, setInCall] = useState<SessionState>(SessionState.Initial);
  const [anrufHistorie, setanrufHistorie] = React.useState<any[]>([]);
  const [muted, setMuted] = React.useState(false);
  const [currentTyp, setCurrentTyp] = React.useState(-1);
  const [notizOpen, setNotizOpen] = React.useState(false);
  const [notiz, setNotiz] = React.useState<string | null>(null);
  const [currentNummer, setCurrentNummer] = React.useState("");
  const [onHold, setOnHold] = React.useState(false);
  function sleep(fn: any) {
    return new Promise((resolve) => {
      // wait 3s before calling fn(par)
      setTimeout(() => resolve(fn()), 3000);
    });
  }

  const notizDing = (note: string, checked: boolean) => {
    if (note.length < 5)
      toast.error(
        "Pflichtangabe. Hört auf nur scheiße hier rein zu schreiben, wir checken das. Bitte eine kurze Notiz hinzufügen."
      );
    else {
      setNotiz(note);
      setNotizOpen(false);

      const xxy = updateAnrufNotiz(note, anrufId);

      if (!checked) return;

      //ticket erstellen mit notiz und user id

      const destElement: any = (
        document.getElementById("ntntxf") as HTMLInputElement
      ).value;
      const nummeroni =
        currentTyp === 1 ? destElement : incomingRufnummerRef.current;
      const xxx = getNummerInfos(nummeroni).then((data) => {
        console.log("lkjhg", data);
        const ticket = {
          betreff: "Anfrage über Notiz",
          text: note,
          kundeName: data.kunde,
          kundeMail: data.mail,
          qmFall: false,
        };
        const xy = createTicket(data.id, ticket);
      });
    }
  };

  const [incSession, setIncSession] = useState<any>("");
  React.useEffect(() => {
    async function holCalls() {
      const xx: any = await sleep(getCallHistory);
      setanrufHistorie(xx);
    }
    if (user?.role != undefined) {
      holCalls();
    }
  }, [user]);

  useEffect(() => {
    async function main() {
      userAgent.start().then(() => {
        // Register the user agent
        // console.log(typeof user?.sid);
        if (typeof user?.sid === "string") {
          registerer.register();
          var diivc = document.getElementById("portal-root");
          if (diivc != null) {
            diivc.style.display = user?.cc_set_telefon === 1 ? "block" : "none";
          }
        } else {
          var diivc = document.getElementById("portal-root");
          if (diivc != null) {
            diivc.style.display = "none";
          }
        }
        //console.log(uri);
        //console.log("reggg");
      });
    }
    if (user?.role != undefined && user?.standort != "ZU") {
      main();
    }
  }, [user]);

  /*
   * Create a user agent
   */

  const inCallRef = useRef(SessionState.Initial);
  const [incoming, setIncoming] = useState(false);
  const [connectionState, setConnectionState] = useState(
    RegistererState.Unregistered
  );
  const [incomingRufnummer, setIncomingRufnummer] = useState("");
  const incomingRufnummerRef = useRef(incomingRufnummer);
  const destinationRef = useRef();
  if (user?.standort == "ZU") return null;
  const mediaElement = getAudioElement("remoteAudio");
  const ring = getAudioElement("ringTest");
  const frei = getAudioElement("freiZeichen");
  function getAudioElement(id: string): HTMLAudioElement {
    const el = document.getElementById(id);
    if (!(el instanceof HTMLAudioElement)) {
      throw new Error(`Element "${id}" not found or not an audio element.`);
    }
    return el;
  }
  const remoteStream = new MediaStream();
  function setupRemoteMedia(session: Session) {
    let sessionDescHandler =
      session.sessionDescriptionHandler as Web.SessionDescriptionHandler;
    sessionDescHandler.peerConnection?.getReceivers().forEach((receiver) => {
      if (receiver.track) remoteStream.addTrack(receiver.track);
    });

    mediaElement.srcObject = remoteStream;
    mediaElement.play();
  }

  function cleanupMedia() {
    mediaElement.srcObject = null;
    mediaElement.pause();
  }
  // const [destination, setDestination] = useState("");

  const xy: any = "";

  const answer = (invitation: Invitation) => {
    invitation.accept();
    logCall("callOn");
  };
  const hangupIn = (session: Session | Inviter) => {
    console.log(inCallRef.current);
    //wenn invitation dann invitation.cancel statt session.bye
    if (inCall === SessionState.Establishing) {
      const invitation = session as Inviter;
      invitation.cancel();
    } else if (inCall === SessionState.Established) session.bye();
  };
  const hangupOut = (session: Session) => {};

  //console.log("user", user);
  const host = user?.standort === "ZU" ? "" : "sip.sipgate.de";
  let uri: any = "";
  if (user?.standort == "ZU") {
    uri = "";
  } else {
    try {
      uri = UserAgent.makeURI(`sip:${user?.sid}@${host}`);
      if (!uri) {
        throw new Error("Failed to create URI");
      }
    } catch (e: any) {
      console.log(e);
    }
  }
  const userAgentOptions: UserAgentOptions = {
    uri,
    authorizationUsername: user?.sid,
    authorizationPassword: user?.spw,
    displayName: user?.stel,
    transportOptions: {
      server: `wss://${host}`,
    },
    logLevel: "error",
  };
  const userAgent = new UserAgent(userAgentOptions);

  /*
   * Setup handling for incoming INVITE requests
   */
  userAgent.delegate = {
    onInvite(invitation: Invitation): void {
      console.log("onInvite");
      console.log("inCallRef", inCallRef.current);
      console.log(invitation.remoteIdentity.uri.user);
      const xy = invitation.remoteIdentity.uri.user;
      let tel = xy;
      const gNum = phoneUtil.parseAndKeepRawInput(xy, "DE");
      let FormatNumb = phoneUtil.format(gNum, PNF.INTERNATIONAL);

      if (phoneUtil.isValidNumber(gNum)) {
        tel = FormatNumb;
      }

      const xyz = getNummerInfos(tel as string).then((val) => {
        console.log("ANRUFINFO:", val);
        if (val.kunde == "none")
          setIncomingRufnummer(invitation.remoteIdentity.uri.user!);
        else setIncomingRufnummer(val.kunde);
      });
      // setIncomingRufnummer(invitation.remoteIdentity.uri.user!);
      incomingRufnummerRef.current = invitation.remoteIdentity.uri.user!;
      if (inCallRef.current == SessionState.Established) {
        console.log("ayayayaya");
        //geht nicht
        //invitation.reject();
        //geht nicht
        logAnruf(3, "0:0:0", "Verpasst", false, tel as string);

        getNummerInfos(tel as string).then((nummerInfoJ) => {
          let neuDat = {
            anruf_id: randomId(),
            anrufTyp_id: 3,
            dauer: "00:00:00",
            nummer: tel,
            firma:
              nummerInfoJ.kunde != "none" ? nummerInfoJ.kunde : "Unbekannt",
            datum: new Date(),
            id: randomId(),
          };
          setanrufHistorie((anrufHistorie) => [neuDat, ...anrufHistorie]);
        });
        return;
      }
      setIncoming(true);
      ring.muted = false;
      ring.play();
      console.log(incomingRufnummer);
      // An Invitation is a Session
      const incomingSession: Session | Invitation = invitation;
      console.log(inCallRef.current);

      setIncSession(incomingSession);
      // Setup incoming session delegate
      incomingSession.delegate = {
        // Handle incoming REFER request.
        onRefer(referral: Referral): void {
          // ...
        },
        onAck(ack: Ack): void {
          let wtf = incomingRufnummer;
          console.log("ack");
          console.log(wtf);
        },
        onBye(bye: Bye): void {
          console.log("bye");
        },
      };

      // Handle incoming session state changes.
      incomingSession.stateChange.addListener((newState: SessionState) => {
        switch (newState) {
          case SessionState.Establishing:
            setIncoming(true);
            ring.muted = true;
            ring.pause();
            console.log("incoming establishing");
            break;
          case SessionState.Established:
            timer.start();
            //TODO erster insert

            ajunr = invitation.remoteIdentity.uri.user!;
            logAnruf(2, "0:0:0", "k.A.", true, ajunr);
            console.log("established");
            setCurrentTyp(2);
            setInCall(SessionState.Established);
            logCall("callOn");
            inCallRef.current = newState;
            console.log("incoming inCall", inCall);
            setupRemoteMedia(incomingSession);
            // Session has been established.

            break;
          case SessionState.Terminating:
            console.log("terminating");
            console.log(incomingSession);
            break;
          case SessionState.Terminated:
            console.log("terminated");
            logCall("callOff");

            console.log(incomingRufnummer);
            timer.stop();
            aktivLogger(
              `${timer.time().h}:${timer.time().m}:${timer.time().s}`
            );
            if (interval !== null) {
              clearInterval(interval);
            }

            setInCall(SessionState.Terminated);
            inCallRef.current = newState;
            console.log("incoming inCall", inCall);
            setIncoming(false);
            ajunr = typeof tel === "string" ? tel : "+49123 456777";

            // erster Handler für Notiz zum Anruf
            if (timer.isStarted()) {
              setNotizOpen(true);
              getNummerInfos(tel as string).then((nummerInfoJ) => {
                let neuDat = {
                  anruf_id: randomId(),
                  anrufTyp_id: 2,
                  dauer: "00:00:00",
                  nummer: tel,
                  firma:
                    nummerInfoJ.kunde != "none"
                      ? nummerInfoJ.kunde
                      : "Unbekannt",
                  datum: new Date(),
                  id: randomId(),
                };
                setanrufHistorie((anrufHistorie) => [neuDat, ...anrufHistorie]);
              });
            } else {
              //zeige und insert als verpasst
              (async function xx() {
                let nummerInfoJ = await getNummerInfos(xy as string);
                let neuDat = {
                  anruf_id: randomId(),
                  anrufTyp_id: 3,
                  dauer: "00:00:00",
                  nummer: invitation.remoteIdentity.uri.user,
                  firma:
                    nummerInfoJ.kunde != "none"
                      ? nummerInfoJ.kunde
                      : "Unbekannt",
                  datum: new Date(),
                  id: randomId(),
                };
                setanrufHistorie((anrufHistorie) => [neuDat, ...anrufHistorie]);
                let formNum = yanduuFormatNummer(
                  invitation.remoteIdentity.uri.user as string
                ).nummer;
                logAnruf(3, "0:0:0", "Verpasst", false, formNum);
              })();
            }
            setCurrentNummer("");

            cleanupMedia();
            setIncomingRufnummer("");

            // Session has terminated.
            break;
          default:
            break;
        }
      });

      // Handle incoming INVITE request.
      let constrainsDefault: MediaStreamConstraints = {
        audio: true,
        video: false,
      };

      const options: InvitationAcceptOptions = {
        sessionDescriptionHandlerOptions: {
          constraints: constrainsDefault,
        },
      };

      // invitation.accept(options);
      // incomingSession.accept(options);
    },
    onConnect() {},
    onRegister(registration: any) {
      // console.log(registration);
    },
  };

  /*
   * Create a Registerer to register user agent
   */
  const registererOptions: RegistererOptions = {
    /* ... */
  };
  const registerer = new Registerer(userAgent, registererOptions);

  registerer.stateChange.addListener((newState: RegistererState) => {
    //console.log(newState);
    switch (newState) {
      case RegistererState.Initial:
        //console.log("register.initial");

        break;
      case RegistererState.Registered:
        //console.log("Register.Registered");
        setConnectionState(RegistererState.Registered);
        break;
      case RegistererState.Terminated:
        //console.log("register.terminated");
        break;
      case RegistererState.Unregistered:
        //console.log("register.unregistered");
        setConnectionState(RegistererState.Unregistered);
        break;

      default:
        break;
    }
  });

  const handleNummerChange = (e: any) => {
    // console.log(e.target.value);
    const xy = e.target.value.replace("+", "00");
    const onlyNums = xy.replace(/[^0-9]/g, "");
    destinationRef.current = onlyNums;
    // setDestination(onlyNums);
    // console.log(destination);
  };
  /*
   * Start the user agent
   */
  // Register the user agent
  // registerer.register();

  // Send an outgoing INVITE request
  const call = async () => {
    userAgent.start().then(async () => {
      let test: any = "";
      console.log("reff", typeof destinationRef);
      if (typeof destinationRef !== "undefined") {
        console.log("refff", destinationRef);
        test = destinationRef.current;
        if (typeof test === "object") test = test.value;
      } else {
        // if(typeof(document.getElementById("ntntxf") as HTMLInputElement).value)
        console.log(
          "asdffff",
          (document.getElementById("ntntxf") as HTMLInputElement).value
        );
      }
      // console.log("tesst", test);
      test = (document.getElementById("ntntxf") as HTMLInputElement).value;
      test = test.replace("+", "00");
      test = test.replace("-", "");
      test = test.replace("/", "");
      test = test.replace("(", "");
      test = test.replace(")", "");
      test = test.trim();
      test = test.replaceAll(" ", "");

      console.log(`sip:${test}@sipgate.de`);
      const target = UserAgent.makeURI(`sip:${test}@sipgate.de`);
      if (!target) {
        throw new Error("Failed to create target URI.");
      }

      let tel = yanduuFormatNummer(test).nummer;

      setCurrentNummer(tel);

      if (await isGesperrteRufnummer(tel)) {
        toast.error(
          "Die eingegebene Rufnummer ist gesperrt. Bitte den Eintrag permanent sperren."
        );
        return;
      }

      let oftAngerufenListe = await isOftAngerufen(tel);
      console.log("Oft Angerufen Check: " + oftAngerufenListe.length);

      if (oftAngerufenListe.length > 0) {
        let shouldDelete = window.confirm(
          "Achtung, willst du wirklich anrufen? Nummer wurde oft angerufen. " +
            oftAngerufenListe.length +
            " mal in den letzten 90 Tagen, zuletzt von " +
            oftAngerufenListe[0].name_betrieb +
            " am " +
            oftAngerufenListe[0].datum
        );
        if (!shouldDelete) {
          return;
        }
      }

      // Create a new Inviter

      const inviterOptions: InviterOptions = {
        /* ... */
      };

      const inviter = new Inviter(userAgent, target, inviterOptions);

      // An Inviter is a Session
      const outgoingSession: Session = inviter;

      // Setup outgoing session delegate
      outgoingSession.delegate = {
        // Handle incoming REFER request.
        onRefer(referral: Referral): void {
          // ...
        },
        onInvite(request, response, statusCode): void {
          console.log("outgoing invite event");
          // ...
        },
        onAck(ack: Ack): void {
          console.log("outgoing ack event");
          // ...
        },
      };

      // Handle outgoing session state changes.
      outgoingSession.stateChange.addListener((newState: SessionState) => {
        switch (newState) {
          case SessionState.Establishing:
            console.log("outgoing establishing");
            frei.volume = 0.4;
            frei.muted = false;
            frei.play();
            setInCall(newState);
            inCallRef.current = newState;
            console.log("inCall", inCall);

            setIncSession(outgoingSession);
            logCall("callOn");

            // Session is establishing.
            break;
          case SessionState.Established:
            console.log("outgoing Established");
            frei.muted = true;
            frei.pause();
            timer.start();
            setupRemoteMedia(outgoingSession);
            inCallRef.current = newState;
            setInCall(newState);
            setCurrentTyp(1);
            console.log("inCall", inCall);
            ajunr = tel;
            logAnruf(1, "0:0:0", "k.A.", true, tel);

            // Session has been established.
            break;
          case SessionState.Terminated:
            console.log("outgoing terminated");

            if (!timer.isStarted()) {
              ajunr = tel;
              logAnruf(1, "0:0:0", "Nicht erreicht", false, tel);
              getNummerInfos(tel as string).then((nummerInfoJ) => {
                let neuDat = {
                  anruf_id: randomId(),
                  anrufTyp_id: 1,
                  dauer: "00:00:00",
                  nummer: tel,
                  firma:
                    nummerInfoJ.kunde != "none"
                      ? nummerInfoJ.kunde
                      : "Unbekannt",
                  datum: new Date(),
                  id: randomId(),
                };
                setanrufHistorie((anrufHistorie) => [neuDat, ...anrufHistorie]);
              });
            } else {
              setNotizOpen(true);
              getNummerInfos(tel as string).then((nummerInfoJ) => {
                let neuDat = {
                  anruf_id: randomId(),
                  anrufTyp_id: 1,
                  dauer: "00:00:00",
                  nummer: tel,
                  firma:
                    nummerInfoJ.kunde != "none"
                      ? nummerInfoJ.kunde
                      : "Unbekannt",
                  datum: new Date(),
                  id: randomId(),
                };
                setanrufHistorie((anrufHistorie) => [neuDat, ...anrufHistorie]);
              });
            }
            // clearInterval(interval);

            if (frei.muted == false) {
              frei.muted = true;
              frei.pause();
            }

            // zweiter handler für notiz zum anruf hinzu
            timer.stop();
            aktivLogger(
              `${timer.time().h}:${timer.time().m}:${timer.time().s}`
            );
            if (interval !== null) {
              clearInterval(interval);
            }
            inCallRef.current = newState;
            setInCall(newState);
            console.log("inCall", inCall);
            cleanupMedia();
            logCall("callOff");
            ajunr = "+49123 456788";
            setCurrentNummer("");
            // Session has terminated.
            break;
          default:
            break;
        }
      });

      // Send the INVITE request
      inviter
        .invite()
        .then(() => {
          // INVITE sent
        })
        .catch((error: Error) => {
          // INVITE did not send
        });
    });
  };

  // Send an outgoing REFER request
  // const transferTarget = UserAgent.makeURI("sip:transfer@example.com");
  /* 
    if (!transferTarget) {
      throw new Error("Failed to create transfer target URI.");
    } */

  /* outgoingSession.refer(transferTarget, {
      // Example of extra headers in REFER request
      requestOptions: {
        extraHeaders: ["X-Referred-By-Someone: Username"],
      },
      requestDelegate: {
        onAccept(): void {
          // ...
        },
      },
    }); 
  }); */
  const portalNode = document.getElementById("portal-root")!;

  dragElement(document.getElementById("portal-root"));
  function dragElement(elmnt: any) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    var moverdiv = document.getElementById("moooover");
    if (moverdiv != null) {
      //console.log("option a");
      // if present, the header is where you move the DIV from:
      moverdiv.onmousedown = dragMouseDown;
    } else {
      //console.log("option b");
      // otherwise, move the DIV from anywhere inside the DIV:
      //elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e: any) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: any) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      //elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      //elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      var classattrib =
        "position: absolute; top: " +
        (elmnt.offsetTop - pos2) +
        "px; left: " +
        (elmnt.offsetLeft - pos1) +
        "px;";
      elmnt.style.cssText = classattrib;
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  const hideHasselPho = (e: any) => {
    var diivc = document.getElementById("portal-root");
    if (diivc != null) {
      diivc.style.display = "none";
    }
  };
  // const [showVerlauf, setShowVerlauf] = useState(true);
  const toggleVerlauf = () => {};
  function mute() {
    setMuted(!muted);
    const xy = incSession as any;
    const xxx = xy.sessionDescriptionHandler.peerConnection;
    console.log(xxx);
    xxx.getSenders().forEach((stream: any) => {
      stream.track.enabled = !stream.track.enabled;
    });
  }

  function hold() {
    setOnHold(!onHold);
    const xx = incSession as Session;
    const sessionDescriptionHandlerOptions: Web.SessionDescriptionHandlerOptions =
      {
        hold: !onHold,
      };
    incSession.sessionDescriptionHandlerOptionsReInvite =
      sessionDescriptionHandlerOptions;
    const options: SessionInviteOptions = {
      requestDelegate: {
        onAccept: (): void => {
          console.log("halte");
        },
        onReject: (): void => {
          console.log("halte nicht");
        },
      },
    };
    console.log(typeof incSession, "ses");

    incSession
      .invite(options)
      .then(() => {})
      .catch((e: Error) => {
        if (e instanceof RequestPendingError) {
          console.log("schon am halten");
        }
        console.log(e);
      });
    // const invitation = incSession as Inviter;
    // invitation
    //   .invite(options)
    //   .then(() => {})
    //   .catch((e: Error) => {
    //     if (e instanceof RequestPendingError) {
    //       console.log("schon am halten");
    //     }
    //     console.log(e);
    //   });
  }

  let noautofill = { "data-form-type": "other" };

  return ReactDOM.createPortal(
    <div>
      <Grid
        container
        direction="row"
        justifyContent="end"
        style={
          user?.role != undefined
            ? { border: "1px solid" }
            : { display: "none" }
        }
      >
        {/* <Stack direction="row" justifyContent="end"> */}
        <Card style={{ zIndex: 9999, minWidth: "600px" }}>
          <div style={{ textAlign: "right" }}>
            <IconButton
              color="primary"
              style={{ float: "right" }}
              onClick={hideHasselPho}
            >
              <Close />
            </IconButton>
            <IconButton
              color="primary"
              id="moooover"
              style={{ float: "right" }}
            >
              <DragIndicator />
            </IconButton>

            <div style={{ float: "left" }}>
              <IconButton onClick={toggleVerlauf}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </div>
            <div style={{ float: "left" }}>
              Telefon{" "}
              {connectionState === RegistererState.Registered ? "V" : "NV"}
            </div>
          </div>
          <Box style={{ marginTop: "24px" }}>
            <CardContent>
              <div
                style={{
                  width: "50%",
                  float: "left",
                  height: "400px",
                  padding: "0px 5px 0px 0px",
                }}
              >
                <List
                  style={{
                    height: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {anrufHistorie.map((ah: any) => (
                    <ListItem
                      button
                      key={ah.anruf_id}
                      style={{ padding: "0px" }}
                      onClick={() =>
                        ((
                          document.getElementById("ntntxf") as HTMLInputElement
                        ).value = ah.nummer)
                      }
                    >
                      <ListItemIcon style={{ minWidth: "10px" }}>
                        {ah.anrufTyp_id === 1 ? (
                          <LocalPhone color="success" />
                        ) : ah.anrufTyp_id === 2 ? (
                          <PhoneCallback color="success" />
                        ) : (
                          <PhoneMissed color="error" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={ah.firma}
                        secondary={
                          <>
                            {ah.nummer} <br /> {VsIndZeit(ah.datum)}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
              <div
                style={{
                  width: "50%",
                  float: "left",
                  padding: "0px 0px 0px 5px",
                }}
              >
                {incomingRufnummer != "" ? (
                  <Typography>
                    Eingehender Anruf von: {incomingRufnummer}
                  </Typography>
                ) : (
                  <Typography></Typography>
                )}
                {user?.role === "Terminierer" && user?.personal_id != 260 && (
                  <TextField
                    inputRef={destinationRef}
                    {...noautofill}
                    // value={destination}
                    disabled
                    fullWidth
                    id="ntntxf"
                  />
                )}
                {(user?.role != "Terminierer" || user?.personal_id == 260) && (
                  <TextField
                    {...noautofill}
                    autoComplete="off"
                    inputRef={destinationRef}
                    // value={destination}
                    id="ntntxf"
                    //type="number"
                    fullWidth
                    onChange={handleNummerChange}
                  />
                )}
                <div style={{ clear: "both" }}></div>{" "}
                <div style={{ clear: "both" }}></div>
                <ButtonGroup variant="text" style={{ width: "100%" }}>
                  <Button style={{ width: "25%" }}>
                    <CallIcons
                      call={call}
                      answer={() => answer(incSession)}
                      hangup={() => hangupIn(incSession)}
                      inCall={inCall}
                      incoming={incoming}
                    />
                  </Button>
                  <Button style={{ width: "25%" }}>
                    <PhoneForwardedRounded
                      color="success"
                      style={{ fontSize: "40px" }}
                    />
                  </Button>
                  <Button style={{ width: "25%" }} onClick={hold}>
                    {onHold && (
                      <PhonePaused color="error" style={{ fontSize: "40px" }} />
                    )}
                    {!onHold && (
                      <PhonePaused
                        color="success"
                        style={{ fontSize: "40px" }}
                      />
                    )}
                  </Button>
                  <Button style={{ width: "25%" }} onClick={mute}>
                    {muted && (
                      <Mic color="error" style={{ fontSize: "40px" }} />
                    )}
                    {!muted && (
                      <MicOffRounded
                        color="success"
                        style={{ fontSize: "40px" }}
                      />
                    )}
                  </Button>
                </ButtonGroup>
                <div style={{ clear: "both" }}></div>
                <div
                  className="btn-group-vertical ml-4 mt-4"
                  style={{ width: "100%" }}
                >
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        /*call.dtmf(1) */
                        sendDtmf("1");
                      }}
                    >
                      1
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("2");
                      }}
                    >
                      2
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("3");
                      }}
                    >
                      3
                    </button>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("4");
                      }}
                    >
                      4
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("5");
                      }}
                    >
                      5
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("6");
                      }}
                    >
                      6
                    </button>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("7");
                      }}
                    >
                      7
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("8");
                      }}
                    >
                      8
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("9");
                      }}
                    >
                      9
                    </button>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("#");
                      }}
                    >
                      #
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("0");
                      }}
                    >
                      0
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-3"
                      onClick={() => {
                        sendDtmf("*");
                      }}
                    >
                      *
                    </button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Box>
        </Card>
      </Grid>
      <AnrufNotiz funcs={[notizOpen, setNotizOpen]} onConfirm={notizDing} />
    </div>,
    portalNode
  );
  async function logCall(msg: string) {
    xxTest().post("/telefon/insertLog", {
      personal_id: user?.personal_id,
      logMessage: msg,
    });
  }

  async function logAnruf(
    anrufTyp: number,
    dauer: string,
    note: string,
    shallCountAndUpdate: boolean,
    telNr: string | null
  ) {
    let tel = telNr == null ? yanduuFormatNummer(ajunr).nummer : telNr;
    let dummeId = -999888;

    const xyz = await insertAnruf({
      personal_id: user?.personal_id,
      nummer: tel,
      dauer: dauer,
      anrufTyp: anrufTyp,
      notiz: note,
    });

    if (shallCountAndUpdate) {
      if (interval !== null) {
        clearInterval(interval);
      }

      interval = setInterval(() => {
        if (ajuid != -66) {
          aktivLogger(`${timer.time().h}:${timer.time().m}:${timer.time().s}`);
        }
      }, 10000);
      dummeId = xyz.insertId;
      setAnrufId(dummeId);
      ajuid = dummeId;
      console.log("anrufid gesetzt", dummeId);
      setNotiz(null);
    }

    return dummeId;
  }
  function sendDtmf(signal: string) {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: "Signal=" + signal + "\r\nDuration=1000",
        },
      },
    };
    console.log("dtmf", incSession._state);
    if (incSession._state == "" || incSession._state != "Established") return;
    incSession.info(options);
  }
  function aktivLogger(dauerxx: any) {
    if (dauerxx == "0:0:0") {
      console.log(ajuid, "Unnötiger log");
    } else {
      updateAnrufDauer(ajuid, dauerxx);
      console.log(ajuid, dauerxx);
    }
  }
};
export default NeuTelefon;
