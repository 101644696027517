// Layouts
import AuthLayout from "./components/alteReactScheisse/layouts/Auth";
import DashboardLayout from "./components/alteReactScheisse/layouts/Dashboard";
import AuthGuard from "./components/alteReactScheisse/AuthGuard";
import { lazy, Suspense } from "react";

// Page components
const Startseite365 = lazy(() => import("./pages/Startseite365/Startseite"));
const Tasks365 = lazy(() => import("./pages/Tasks365"));
const Kunden = lazy(() => import("./pages/Kunden"));
const Aufträge = lazy(() => import("./pages/KundenJulien"));
const Chat = lazy(() => import("./pages/Chat365"));
const Einstellungen = lazy(() => import("./pages/Einstellungen"));
const Termine = lazy(() => import("./pages/Termine"));
const Crawler = lazy(() => import("./pages/Crawler"));
const Angebote = lazy(() => import("./pages/Angebote"));
const Projekte = lazy(() => import("./pages/Projekte"));
const ProjektDetails = lazy(() => import("./pages/ProjektDetails"));
const KVs = lazy(() => import("./pages/KVs"));
const KVsIT = lazy(() => import("./pages/KVsIT"));
const SEO_Kunden = lazy(() => import("./pages/SEO_Kunden"));
const Google_Ads_Manager = lazy(() => import("./pages/Google_Ads_Manager"));
const RTA_Manager = lazy(() => import("./pages/RTA_Manager"));
const BTR_Manager = lazy(() => import("./pages/BTR_Manager"));
const Verwaltung = lazy(() => import("./pages/Verwaltung365/Verwaltung"));
const VerwaltungPersonal = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_Personal")
);
const VerwaltungCrawler = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_Crawler")
);
const VerwaltungKundenStatus = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_KundenStatus")
);
const VerwaltungMATasks = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_MATasks")
);
const VerwaltungServer = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_Server")
);
const VerwaltungShoutbox = lazy(
  () => import("./pages/Verwaltung365/Verwaltung_Shoutbox")
);
const Dokumentation = lazy(() => import("./pages/DokumentationUebersicht"));
const AnfrageIT = lazy(() => import("./pages/Anfrage-IT"));
const ProjekteVertrieb = lazy(() => import("./pages/ProjekteVertrieb"));

const BesondereKundenVertrieb = lazy(
  () => import("./pages/BesondereKundenVertrieb")
);
const KollegenKalender = lazy(() => import("./pages/KollegenKalender"));
const DokumentationDetails = lazy(() => import("./pages/DokumentationDetails"));
const StatsAllgemein = lazy(
  () => import("./pages/Statistiken365/Statistiken_Allgemein")
);
const StatsUmsatz = lazy(
  () => import("./pages/Statistiken365/Statistiken_Umsatz")
);
const StatsCashflow = lazy(
  () => import("./pages/Statistiken365/Statistiken_Cashflow")
);
const StatsVertrieb = lazy(
  () => import("./pages/Statistiken365/Statistiken_Vertrieb")
);
const StatsTerminierung = lazy(
  () => import("./pages/Statistiken365/Statistiken_Terminierung")
);
const StatsBackoffice = lazy(
  () => import("./pages/Statistiken365/Statistiken_Backoffice")
);
const StatsCrawler = lazy(
  () => import("./pages/Statistiken365/Statistiken_Crawler")
);
const Support = lazy(() => import("./pages/Support"));
const Vertraege = lazy(() => import("./pages/VertraegeVerwaltung"));
const ITAnfragen = lazy(
  () => import("./pages/Verwaltung365/Support_IT_Asana_Tickets")
);
const Buchhaltung = lazy(() => import("./pages/Buchhaltung"));
const QmManagement = lazy(() => import("./pages/QM-Management"));
const Teams = lazy(() => import("./pages/Teams"));
const ContentPlaner = lazy(() => import("./pages/ContentPlaner"));
const SmmPlaner = lazy(() => import("./pages/SmmPlaner"));
const KeywordPlaner = lazy(() => import("./pages/KeywordPlaner"));
const BacklinkManager = lazy(() => import("./pages/BacklinkManager"));
const Page404 = lazy(() => import("./pages/Page404"));
const SignIn = lazy(() => import("./pages/SignIn"));
const Page500 = lazy(() => import("./pages/Page500"));
const MeineTickets = lazy(() => import("./pages/MeineTickets"));
const Changelog = lazy(() => import("./pages/Changelog"));
const Provision = lazy(() => import("./pages/Provision"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Startseite365 />
          </Suspense>
        ),
      },
      {
        path: "Kunden",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Kunden />
          </Suspense>
        ),
      },
      {
        path: "BesondereKunden",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <BesondereKundenVertrieb />
          </Suspense>
        ),
      },
      {
        path: "ProjekteVertrieb",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <ProjekteVertrieb />
          </Suspense>
        ),
      },
      {
        path: "KollegenKalender",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <KollegenKalender />
          </Suspense>
        ),
      },
      {
        path: "Auftraege",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Aufträge />
          </Suspense>
        ),
      },
      {
        path: "Einstellungen",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Einstellungen />
          </Suspense>
        ),
      },
      {
        path: "Changelog",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Changelog />
          </Suspense>
        ),
      },
      {
        path: "Tasks365",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Tasks365 />
          </Suspense>
        ),
      },
      {
        path: "Messaging",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Chat />
          </Suspense>
        ),
      },
      {
        path: "Termine",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Termine />
          </Suspense>
        ),
      },
      {
        path: "Crawler",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Crawler />
          </Suspense>
        ),
      },
      {
        path: "Provision",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Provision />
          </Suspense>
        ),
      },
      {
        path: "QM-Management",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <QmManagement />
          </Suspense>
        ),
      },
      {
        path: "Teams",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Teams />
          </Suspense>
        ),
      },
      {
        path: "Angebote",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Angebote />
          </Suspense>
        ),
      },
      {
        path: "KeywordPlaner",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <KeywordPlaner />
          </Suspense>
        ),
      },
      {
        path: "SmmPlaner",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <SmmPlaner />
          </Suspense>
        ),
      },
      {
        path: "ContentPlaner",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <ContentPlaner />
          </Suspense>
        ),
      },
      {
        path: "Backlink-Manager",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <BacklinkManager />
          </Suspense>
        ),
      },
      {
        path: "Support",
        children: [
          {
            path: "MeineTickets",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <MeineTickets />
              </Suspense>
            ),
          },
          {
            path: "IT-Anfragen",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <ITAnfragen />
              </Suspense>
            ),
          },
          {
            path: "",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <Support />
              </Suspense>
            ),
          },
          {
            path: ":ProjektId",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <ProjektDetails />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "Buchhaltung",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Buchhaltung />
          </Suspense>
        ),
      },
      {
        path: "Vertraege",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Vertraege />
          </Suspense>
        ),
      },
      {
        path: "Projekte",
        children: [
          {
            path: ":ProjektId",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <ProjektDetails />
              </Suspense>
            ),
          },
          {
            path: "alle",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <Projekte />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "KVs",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <KVs />
          </Suspense>
        ),
      },
      {
        path: "KVs-IT",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <KVsIT />
          </Suspense>
        ),
      },
      {
        path: "Google-Ads-Manager",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Google_Ads_Manager />
          </Suspense>
        ),
      },
      {
        path: "RTA-Manager",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <RTA_Manager />
          </Suspense>
        ),
      },
      {
        path: "SEO-Liste",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <SEO_Kunden />
          </Suspense>
        ),
      },
      {
        path: "BTRPlaner",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <BTR_Manager />
          </Suspense>
        ),
      },
      {
        path: "Dokumentation",
        children: [
          {
            path: ":docId",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <DokumentationDetails />
              </Suspense>
            ),
          },
          {
            path: "Start",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <Dokumentation />
              </Suspense>
            ),
          },
          {
            path: "Anfrage-IT",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <AnfrageIT />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "Verwaltung",
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <Verwaltung />
              </Suspense>
            ),
          },
          {
            path: "Personal",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungPersonal />
              </Suspense>
            ),
          },
          {
            path: "Crawler",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungCrawler />
              </Suspense>
            ),
          },
          {
            path: "Kunden-Status",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungKundenStatus />
              </Suspense>
            ),
          },
          {
            path: "MA-Tasks",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungMATasks />
              </Suspense>
            ),
          },
          {
            path: "Server",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungServer />
              </Suspense>
            ),
          },
          {
            path: "Shoutbox",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <VerwaltungShoutbox />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "Statistiken",
        children: [
          {
            path: "Allgemein",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsAllgemein />
              </Suspense>
            ),
          },
          {
            path: "Umsatz",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsUmsatz />
              </Suspense>
            ),
          },
          {
            path: "Cashflow",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsCashflow />
              </Suspense>
            ),
          },
          {
            path: "Vertrieb",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsVertrieb />
              </Suspense>
            ),
          },
          {
            path: "Terminierung",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsTerminierung />
              </Suspense>
            ),
          },
          {
            path: "Backoffice",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsBackoffice />
              </Suspense>
            ),
          },
          {
            path: "Crawler",
            element: (
              <Suspense fallback={<>Lädt...</>}>
                <StatsCrawler />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: (
      <Suspense fallback={<>Lädt...</>}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: "sign-in",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <SignIn />
          </Suspense>
        ),
      },
      {
        path: "404",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Page404 />
          </Suspense>
        ),
      },
      {
        path: "500",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Page500 />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<>Lädt...</>}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: "*",
        element: (
          <Suspense fallback={<>Lädt...</>}>
            <Page404 />
          </Suspense>
        ),
      },
    ],
  },
];

export default routes;
