import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../../GlobalStyle";
import Navbar from "../../navbar/Navbar";
//import navItems from "../components/sidebar/itItems";
import Sidebar from "../../sidebar/Sidebar";
import useAuth from "../../../hooks/useAuth";
import { SY365GlobalVars } from "../../../config";
import { Home, Briefcase, BarChart } from "react-feather";
import {
  AccountBalance,
  AccountBox,
  Bookmark,
  CalendarToday,
  CompareArrows,
  ContactMail,
  CreditScore,
  CurrencyExchange,
  Email,
  EmojiPeople,
  EventAvailable,
  Facebook,
  FolderSpecial,
  FormatColorTextOutlined,
  Google,
  Groups,
  InsertDriveFile,
  LibraryBooks,
  Link,
  ListAlt,
  ManageSearch,
  People,
  PeopleOutline,
  Person,
  PlaylistAddCheck,
  Terminal,
} from "@mui/icons-material";
import { SidebarItemsType } from "../sidebar";

const drawerWidth = 280;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [msgCnt, setmsgCnt] = useState("0");
  const [KV_IT, setKV_IT] = useState("0");
  const [KV_Vertrieb, setKV_Vertrieb] = useState("0");
  const [KVI, setKVI] = useState("0");

  React.useEffect(() => {
    const interval = setInterval(() => {
      //console.log("inter", SY365GlobalVars);
      setmsgCnt(String(SY365GlobalVars.Messages));
      setKV_IT(String(SY365GlobalVars.KV_IT));
      setKV_Vertrieb(String(SY365GlobalVars.KV_Vertrieb));
      setKVI(String(SY365GlobalVars.KVI));
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const pagesSectionReal = [] as SidebarItemsType[];
  pagesSectionReal.push({
    href: "/",
    icon: Home,
    title: "Start",
  } as SidebarItemsType);

  pagesSectionReal.push({
    href: "/Messaging",
    icon: Email,
    title: "Messaging",
  } as SidebarItemsType);

  pagesSectionReal.push({
    href: "/Teams",
    icon: Groups,
    title: "Teams",
  } as SidebarItemsType);

  if (
    user?.role === "Buchhaltung" ||
    user?.role === "IT" ||
    user?.role === "MOY"
  ) {
    pagesSectionReal.push({
      icon: CalendarToday,
      title: "Termine & Tasks",
      href: "",
      children: [
        {
          href: "/Termine",
          icon: CalendarToday,
          title: "Termine",
        },
        {
          href: "/KollegenKalender",
          icon: EventAvailable,
          title: "Kollegen",
        },
        {
          href: "/Tasks365",
          icon: ListAlt,
          title: "Tasks",
        },
      ],
    } as SidebarItemsType);
  } else if (user?.personal_id === 288 || user?.personal_id === 15) {
    pagesSectionReal.push({
      icon: CalendarToday,
      title: "Termine & Kalender",
      href: "",
      children: [
        {
          href: "/Termine",
          icon: CalendarToday,
          title: "Termine",
        },
        {
          href: "/Tasks365",
          icon: ListAlt,
          title: "Tasks",
        },
        {
          href: "/KollegenKalender",
          icon: EventAvailable,
          title: "Kollegen",
        },
      ],
    } as SidebarItemsType);
  } else {
    pagesSectionReal.push({
      icon: CalendarToday,
      title: "Termine & Kalender",
      href: "",
      children: [
        {
          href: "/Termine",
          icon: CalendarToday,
          title: "Termine",
        },
        {
          href: "/Tasks365",
          icon: ListAlt,
          title: "Tasks",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Terminierer") {
    pagesSectionReal.push({
      icon: PeopleOutline,
      title: "Kunden",
      href: "",
      children: [
        {
          href: "/Kunden",
          icon: People,
          title: "Kunden Übersicht",
        },
        {
          href: "/Crawler",
          icon: LibraryBooks,
          title: "Crawler",
        },
      ],
    } as SidebarItemsType);
  }

  if (
    user?.role === "MOY" ||
    user?.role === "Buchhaltung" ||
    user?.role === "Vertrieb"
  ) {
    pagesSectionReal.push({
      icon: PeopleOutline,
      title: "Kunden",
      href: "",
      children: [
        {
          href: "/Kunden",
          icon: People,
          title: "Kunden Übersicht",
        },
        {
          href: "/BesondereKunden",
          icon: EmojiPeople,
          title: "KvI",
          badge: KVI,
        },
        {
          href: "/Angebote",
          icon: InsertDriveFile,
          title: "Angebote",
        },
        {
          href: "/KVs",
          icon: CompareArrows,
          title: "KVs",
          badge: KV_Vertrieb,
        },
        {
          href: "/Crawler",
          icon: LibraryBooks,
          title: "Crawler",
        },
        {
          href: "/Provision",
          icon: CurrencyExchange,
          title: "Provision",
        },
        {
          href: "/ProjekteVertrieb",
          icon: FolderSpecial,
          title: "Web Projekte",
        },
      ],
    } as SidebarItemsType);
  }

  if (
    user?.role === "MOY" ||
    user?.role === "Buchhaltung" ||
    user?.role === "IT"
  ) {
    pagesSectionReal.push({
      icon: Terminal,
      title: "Aufträge",
      href: "",
      children: [
        {
          href: "/Auftraege",
          icon: People,
          title: "Aufträge",
        },
        {
          href: "/Projekte/alle",
          icon: FolderSpecial,
          title: "Projekte",
        },
        {
          href: "/KVs-IT",
          icon: CompareArrows,
          title: "KVs",
          badge: KV_IT,
        },
        {
          href: "/SEO-Liste",
          icon: PlaylistAddCheck,
          title: "SEO Manager",
        },
        {
          href: "/Backlink-Manager",
          icon: Link,
          title: "Backlink Manager",
        },
        {
          href: "/ContentPlaner",
          icon: FormatColorTextOutlined,
          title: "Content Manager",
        },
        {
          href: "/Google-Ads-Manager",
          icon: CreditScore,
          title: "ADW Manager",
        },
        {
          href: "/RTA-Manager",
          icon: AccountBox,
          title: "RTA Manager",
        },
        {
          href: "/SmmPlaner",
          icon: Facebook,
          title: "Social Manager",
        },
        {
          href: "/KeywordPlaner",
          icon: Google,
          title: "Keyword Manager",
        },
        {
          href: "/BTRPlaner",
          icon: Person,
          title: "BTR Manager",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "MOY" || user?.role === "Buchhaltung") {
    pagesSectionReal.push({
      icon: AccountBalance,
      title: "Accounting",
      href: "",
      children: [
        {
          href: "/Buchhaltung",
          icon: AccountBalance,
          title: "Buchhaltung",
        },
        {
          href: "/Vertraege",
          icon: InsertDriveFile,
          title: "Verträge Verwalten",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Vertrieb") {
    pagesSectionReal.push({
      href: "",
      icon: BarChart,
      title: "Statistik",
      children: [
        {
          href: "/Statistiken/Vertrieb",
          title: "Vertrieb",
        },
        {
          href: "/Statistiken/Terminierung",
          title: "Terminierung",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Terminierer") {
    pagesSectionReal.push({
      href: "",
      icon: BarChart,
      title: "Statistik",
      children: [
        {
          href: "/Statistiken/Terminierung",
          title: "Terminierung",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "IT") {
    pagesSectionReal.push({
      href: "",
      icon: BarChart,
      title: "Statistik",
      children: [
        {
          href: "/Statistiken/Backoffice",
          title: "Backoffice",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Buchhaltung") {
    pagesSectionReal.push({
      href: "",
      icon: BarChart,
      title: "Statistik",
      children: [
        {
          href: "/Statistiken/Backoffice",
          title: "Backoffice",
        },
        {
          href: "/Statistiken/Vertrieb",
          title: "Vertrieb",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "MOY") {
    pagesSectionReal.push({
      href: "",
      icon: BarChart,
      title: "Statistik",
      children: [
        {
          href: "/Statistiken/Allgemein",
          title: "Allgemein",
        },
        {
          href: "/Statistiken/Umsatz",
          title: "Umsatz",
        },
        {
          href: "/Statistiken/Cashflow",
          title: "Cashflow",
        },
        {
          href: "/Statistiken/Vertrieb",
          title: "Vertrieb",
        },
        {
          href: "/Statistiken/Terminierung",
          title: "Terminierung",
        },
        {
          href: "/Statistiken/Backoffice",
          title: "IT & Support",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Buchhaltung" || user?.role === "MOY") {
    pagesSectionReal.push({
      href: "",
      icon: ContactMail,
      title: "Support",
      children: [
        {
          href: "/Support",
          icon: ContactMail,
          title: "Neue Tickets",
        },
        {
          href: "/Support/MeineTickets",
          icon: ContactMail,
          title: "Meine Tickets",
        },
        {
          href: "/Support/IT-Anfragen",
          icon: ContactMail,
          title: "IT Anfragen",
        },
      ],
    } as SidebarItemsType);
  } else {
    pagesSectionReal.push({
      href: "/Support/MeineTickets",
      icon: ContactMail,
      title: "Meine Tickets",
    } as SidebarItemsType);
  }

  if (user?.role === "MOY") {
    pagesSectionReal.push({
      href: "",
      icon: Briefcase,
      title: "Verwaltung",
      children: [
        {
          href: "/Verwaltung/Personal",
          icon: ManageSearch,
          title: "Mitarbeiter",
        },
        {
          href: "/Verwaltung/Shoutbox",
          icon: ManageSearch,
          title: "Shoutbox",
          badge: 1,
        },
        {
          href: "/Verwaltung/Kunden-Status",
          icon: ManageSearch,
          title: "Kunden Status",
        },
        {
          href: "/Verwaltung/MA-Tasks",
          icon: ManageSearch,
          title: "Mitarbeiter Tasks",
        },
        {
          href: "/QM-Management",
          icon: ManageSearch,
          title: "QM Management",
        },
        {
          href: "/Verwaltung/Crawler",
          icon: ManageSearch,
          title: "Crawler",
        },
        {
          href: "/Verwaltung/Server",
          icon: ManageSearch,
          title: "Server & Backup",
        },
      ],
    } as SidebarItemsType);
  }

  if (user?.role === "Buchhaltung") {
    pagesSectionReal.push({
      href: "",
      icon: Briefcase,
      title: "Verwaltung",
      children: [
        {
          href: "/Verwaltung/Personal",
          icon: ManageSearch,
          title: "Mitarbeiter",
        },
        {
          href: "/Verwaltung/Shoutbox",
          icon: ManageSearch,
          title: "Shoutbox",
          badge: 1,
        },
        {
          href: "/Verwaltung/MA-Tasks",
          icon: ManageSearch,
          title: "Mitarbeiter Tasks",
        },
        {
          href: "/QM-Management",
          icon: ManageSearch,
          title: "QM Management",
        },
      ],
    } as SidebarItemsType);
  }

  pagesSectionReal.push({
    href: "/Dokumentation/Start",
    icon: Bookmark,
    title: "Guides & Hilfe",
  } as SidebarItemsType);

  const navItems = [
    {
      title: "Seiten",
      pages: pagesSectionReal,
    },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  const [routes, setRoutes] = useState(navItems);
  const changeRoutes = (routes: any) => {
    setRoutes(routes);
  };
  useEffect(() => {
    changeRoutes(navItems);
    // setRoutes(navItems);
  }, [user, KV_Vertrieb]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={routes}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={routes}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5} className="ys_genBg">
          {children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default Dashboard;
