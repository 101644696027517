import { createGlobalStyle } from "styled-components/macro";
import { ThemeProps } from "styled-components/macro";
import { Theme } from "@mui/material";

export type GlobalStyleProps = {
  theme: ThemeProps<Theme> & { palette: any };
};

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export default GlobalStyle;
