import { xxTest } from "./api";

async function getChats() {
  const xx = await xxTest().get(`/chat/getChats/`);
  const json = xx.data;
  return json;
}
async function getChatHistory(partnerID: any, skpRows: any) {
  const xx = await xxTest().get(`/chat/getChatHistory/${partnerID}/${skpRows}`);
  const json = xx.data;
  return json;
}
async function setChatRead(partnerID: any) {
  const xx = await xxTest().get(`/chat/setChatRead/${partnerID}`);
  const json = xx.data;
  return json;
}

async function getChatPartnerOptions() {
  const xx = await xxTest().get(`/chat/getChatPartnerOptions/`);
  const json = xx.data;
  return json;
}
async function getSysMsg() {
  const xx = await xxTest().get(`/chat/getSysMsg/`);
  const json = xx.data;
  return json;
}
async function getDirectMsg() {
  const xx = await xxTest().get(`/chat/getDirectMsg/`);
  const json = xx.data;
  return json;
}
async function postMemo(data: any) {
  const api_url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SERVER_URL_DEV_API
      : process.env.REACT_APP_SERVER_URL_LIVE_API;

  const token = localStorage.getItem("accessToken");
  const res33 = await fetch(api_url + `/chat/postMemo/`, {
    body: data,
    headers: new Headers({
      authorization: `Bearer ${token}`,
    }),
    method: "POST",
  });
  const rs3 = await res33.text();
  return rs3;
}

async function getChatPartnerInfos(partnerID: any) {
  const xx = await xxTest().get(`/chat/getChatPartnerInfos/${partnerID}`);
  const json = xx.data;
  return json;
}
async function likeMessage(data: any) {
  const xx = await xxTest().post(`/chat/likeMessage/`, data);
  const json = xx.data;
  return json;
}

async function sendChatFile(data: any) {
  const api_url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SERVER_URL_DEV_API
      : process.env.REACT_APP_SERVER_URL_LIVE_API;

  const token = localStorage.getItem("accessToken");
  const res33 = await fetch(api_url + `/chat/sendChatFile/`, {
    body: data,
    headers: new Headers({
      authorization: `Bearer ${token}`,
      //"Content-Type": "application/x-www-form-urlencoded", //"application/x-www-form-urlencoded", "multipart/form-data"
    }),
    method: "POST",
  });
  const rs3 = await res33.json();
  return rs3;
}

export {
  getChats,
  getChatHistory,
  getChatPartnerOptions,
  setChatRead,
  getSysMsg,
  getDirectMsg,
  postMemo,
  getChatPartnerInfos,
  likeMessage,
  sendChatFile,
};
