import { Avatar, Tooltip } from "@mui/material";
import { Android, Group } from "@mui/icons-material";
import { deepOrange } from "@mui/material/colors";
import ReactTimeAgo from "react-time-ago";
import { SY365GlobalVars } from "../../config";
import { getPersonalInfo2 } from "../../_apis/personal";
import { randomId } from "@mui/x-data-grid-generator";
import moment from "moment";

export const VsIndZeit = (anTS: Date, format = "None", show = "DateTime") => {
  var min10 = new Date().getTime() - 5 * 60 * 1000;
  var ts = new Date(anTS).getTime();
  var tdiff = min10 - ts;

  if (tdiff < 60 * 60 * 1000 || format != "None") {
    return moment(anTS).format("DD.MM.YYYY  HH:mm");
  } else {
    if (show === "Date") {
      return new Date(anTS).toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return new Date(anTS).toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
};

export const ErfahrungSeitKurz = (anTS: Date) => {
  const second = 1000;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day; // approximately
  const year = 365 * day; // approximately

  const now = new Date().getTime();
  const then = new Date(anTS).getTime();

  let difference = now - then;

  if (difference / year >= 1) {
    return Math.round(difference / year) + " Jahre";
  } else if (difference / month >= 1) {
    return Math.round(difference / month) + " Monate";
  } else if (difference / week >= 1) {
    return Math.round(difference / week) + " Wochen";
  } else {
    return Math.round(difference / day) + " Tage";
  }
};

export const numFormAbk = (num: any, digits: any) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const ProfilPicVsSys = (
  chatPartnerID: any,
  chatPartnerName: any,
  callSrc: any,
  size = 40
) => {
  //console.log("PPName", chatPartnerID, chatPartnerName, callSrc);

  let people = JSON.parse(localStorage.getItem("LohnEsel") as string);

  if (chatPartnerName == null) {
    try {
      chatPartnerName =
        people[
          people.findIndex((modx: any) => modx.personal_id === chatPartnerID)
        ].name_betrieb;
    } catch {
      let namkkk = getPersonalInfo2(chatPartnerID).then((result: any) => {
        chatPartnerName = result.name_betrieb;
        console.log("55624648554456", result.name_betrieb);
      });
    }
  }
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    if (name.indexOf(" ") > -1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: size,
          height: size,
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: size,
          height: size,
        },
        children: `${name.charAt(0)}`,
      };
    }
  }
  let pic = null;

  if (chatPartnerID >= 10000) {
    return (
      <Avatar sx={{ height: size, width: size }}>
        <Group />
      </Avatar>
    );
  } else {
    if (chatPartnerID === 0) {
      return (
        <Avatar sx={{ bgcolor: deepOrange[500], height: size, width: size }}>
          <Android />
        </Avatar>
      );
    } else {
      // console.log("peops", people);

      if (people.length > 0) {
        pic =
          people.findIndex(
            (modx: any) => modx.personal_id === chatPartnerID
          ) !== -1
            ? people[
                people.findIndex(
                  (modx: any) => modx.personal_id === chatPartnerID
                )
              ].profilbild
            : null;
      }

      if (pic != null)
        return (
          <Tooltip title={chatPartnerName} key={randomId()}>
            <Avatar
              key={randomId()}
              alt="??"
              src={
                "data:image/jpg;base64," + Buffer.from(pic).toString("base64")
              }
              sx={{ height: size, width: size }}
            />
          </Tooltip>
        );
      else
        return (
          <Tooltip title={chatPartnerName} key={randomId()}>
            <Avatar sx={stringAvatar(chatPartnerName).sx}>
              {stringAvatar(chatPartnerName).children}
            </Avatar>
          </Tooltip>
        );
    }
  }
};
