export function yanduuFormatNummer(tel: any) {
  try {
    const PNF = require("google-libphonenumber").PhoneNumberFormat;
    const phoneUtil =
      require("google-libphonenumber").PhoneNumberUtil.getInstance();
    const gNum = phoneUtil.parseAndKeepRawInput(tel, "DE");
    let FormatNumb = phoneUtil.format(gNum, PNF.INTERNATIONAL);

    if (phoneUtil.isValidNumber(gNum)) {
      tel = FormatNumb;
      return { nummer: FormatNumb, formated: true };
    } else {
      return { nummer: tel, formated: false };
    }
  } catch {
    return { nummer: tel, formated: false };
  }
}
export function formatWährung(gesamtschulden: number, währung: string) {
  if (währung === "CHF")
    return new Intl.NumberFormat("ch-CH", {
      style: "currency",
      currency: "CHF",
    }).format(gesamtschulden);
  else
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(gesamtschulden);
}

export function percIncrease(a: any, b: any) {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }
  return Math.floor(percent);
}

export function yanStringShortener(str: any, n: any) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const angebotModule = [
  {
    title: "OSM-Basic",
    typ: "BAS",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    texteQuartal: 1,
    keywords: 10,
  },
  {
    title: "OSM-Excellence",
    typ: "EXC",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    texteQuartal: 2,
    keywords: 15,
  },
  {
    title: "OSM-Executive",
    typ: "CUT",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    texteQuartal: 3,
    keywords: 20,
  },
  {
    title: "OSM Consulting",
    typ: "CON",
    disabled: false,
    sessionsProMonat: 1,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "KI Marketing",
    typ: "KIM",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "KI Support",
    typ: "KIS",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Google Ads",
    typ: "ADW",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    anzahlKampagnen: 1,
    AdsKontoVorhanden: "false",
    AktiveKampagnenvorhanden: "false",
    AktivesConversionTracking: "false",
    shoppingLeistung: "false",
    MerchantCenterKontovorhanden: "false",
    ProduktfeedMCvorhanden: "false",
  },
  {
    title: "Social Media",
    typ: "SMM",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    anzahlProfile: 3,
    smmneukostenEin: "Neueinrichtung",
    smmbeworben: "NB",
    anzahlPosts: 4,
  },
  {
    title: "Webdesign",
    typ: "WEB",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    pflichtenheftCheck: true,
    pflichtenheftText: "",
    finanzierung: "NON",
    finanzierungAnzahlung: 30,
    finanzierungLaufzeit: 6,
    websystem: "Wordpress",
    unterseiten: 10,
    checkedWEBHost: true,
  },
  {
    title: "Shop Erstellung",
    typ: "SHO",
    pflichtenheftCheck: true,
    pflichtenheftText: "",
    finanzierung: "NON",
    finanzierungAnzahlung: 30,
    finanzierungLaufzeit: 6,
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    portierung: "OhnePort",
    portierungKosten: 1000,
    shopsystem: "WooCommerce",
    checkedWEBHost: true,
  },
  {
    title: "Hosting",
    typ: "HOS",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Backlinkpaket 1",
    typ: "BL1",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Backlinkpaket 2",
    typ: "BL2",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Backlinkpaket Pro",
    typ: "BLP",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Fokus / Bunte / Chip Artikel",
    typ: "BUR",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    anzahlArtikel: 1,
    BurdaPlattform: "Focus",
  },
  {
    title: "Content",
    typ: "CNT",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    contentModell: "Abo",
    contentAnzahl: 1,
  },
  {
    title: "Landingpage",
    typ: "LP1",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    anzahlLPs: 1,
    aboModell: false,
  },
  {
    title: "Multi-Landingpage",
    typ: "MLP",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    anzahlLPs: 1,
    aboModell: false,
  },
  {
    title: "Rechtstexte",
    typ: "LAW",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "PR-Beratung",
    typ: "PRB",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Retargeting Ads",
    typ: "RTA",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Google MyBusiness",
    typ: "GMB",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "YouTube",
    typ: "YTB",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Betreuung",
    typ: "BTR",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  {
    title: "Fotografie",
    typ: "FOT",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
    sessions: 0,
    portrais: 0,
    drohnenVid: 0,
    produktFotos: 0,
    sliderVid: 0,
    tonVid: 0,
  },
  {
    title: "Premuim Support",
    typ: "SUP",
    disabled: false,
    streichEin: 0,
    streichMon: 0,
    kostenMon: 0,
    kostenEin: 0,
  },
  /*

    {
      title: "Yanduu Professionals 1",
      typ: "YP1",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Yanduu Professionals 2",
      typ: "YP2",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Yanduu Professionals 3",
      typ: "YP3",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Yanduu Professionals 4",
      typ: "YP4",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },

    {
      title: "Social Media PR1",
      typ: "PRSM1",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Social Media PR2",
      typ: "PRSM2",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Social Media PR3",
      typ: "PRSM3",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Social Media PR4",
      typ: "PRSM4",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    },
    {
      title: "Social Media PR5",
      typ: "PRSM5",
      disabled: false,
      streichEin: 0,
      streichMon: 0,
      kostenMon: 0,
      kostenEin: 0,
    }, */
];

export const angebotModulpreise = [
  { typ: "BAS", laufzeit: 6, einrichung: 1500, mbpreis: 550 },
  { typ: "BAS", laufzeit: 12, einrichung: 1500, mbpreis: 550 },
  { typ: "BAS", laufzeit: 24, einrichung: 1500, mbpreis: 500 },
  { typ: "BAS", laufzeit: 36, einrichung: 1500, mbpreis: 480 },
  { typ: "BAS", laufzeit: 48, einrichung: 1500, mbpreis: 470 },
  { typ: "BAS", laufzeit: 60, einrichung: 1500, mbpreis: 460 },
  { typ: "EXC", laufzeit: 6, einrichung: 2000, mbpreis: 950 },
  { typ: "EXC", laufzeit: 12, einrichung: 2000, mbpreis: 950 },
  { typ: "EXC", laufzeit: 24, einrichung: 2000, mbpreis: 900 },
  { typ: "EXC", laufzeit: 36, einrichung: 2000, mbpreis: 880 },
  { typ: "EXC", laufzeit: 48, einrichung: 2000, mbpreis: 860 },
  { typ: "EXC", laufzeit: 60, einrichung: 2000, mbpreis: 840 },
  { typ: "CUT", laufzeit: 6, einrichung: 2500, mbpreis: 1550 },
  { typ: "CUT", laufzeit: 12, einrichung: 2500, mbpreis: 1550 },
  { typ: "CUT", laufzeit: 24, einrichung: 2500, mbpreis: 1500 },
  { typ: "CUT", laufzeit: 36, einrichung: 2500, mbpreis: 1460 },
  { typ: "CUT", laufzeit: 48, einrichung: 2500, mbpreis: 1440 },
  { typ: "CUT", laufzeit: 60, einrichung: 2500, mbpreis: 1400 },
  { typ: "CON", laufzeit: 0, einrichung: 400, mbpreis: 300 },
  { typ: "KIM", laufzeit: 0, einrichung: 2000, mbpreis: 700 },
  { typ: "KIS", laufzeit: 0, einrichung: 250, mbpreis: 100 },
  { typ: "BL1", laufzeit: 6, einrichung: 500, mbpreis: 105 },
  { typ: "BL1", laufzeit: 12, einrichung: 500, mbpreis: 105 },
  { typ: "BL1", laufzeit: 24, einrichung: 500, mbpreis: 100 },
  { typ: "BL1", laufzeit: 36, einrichung: 500, mbpreis: 95 },
  { typ: "BL1", laufzeit: 48, einrichung: 500, mbpreis: 87 },
  { typ: "BL1", laufzeit: 60, einrichung: 500, mbpreis: 80 },
  { typ: "BL2", laufzeit: 6, einrichung: 500, mbpreis: 185 },
  { typ: "BL2", laufzeit: 12, einrichung: 500, mbpreis: 185 },
  { typ: "BL2", laufzeit: 24, einrichung: 500, mbpreis: 175 },
  { typ: "BL2", laufzeit: 36, einrichung: 500, mbpreis: 165 },
  { typ: "BL2", laufzeit: 48, einrichung: 500, mbpreis: 155 },
  { typ: "BL2", laufzeit: 60, einrichung: 500, mbpreis: 145 },
  { typ: "BLP", laufzeit: 6, einrichung: 500, mbpreis: 105 },
  { typ: "BLP", laufzeit: 12, einrichung: 500, mbpreis: 105 },
  { typ: "BLP", laufzeit: 24, einrichung: 500, mbpreis: 100 },
  { typ: "BLP", laufzeit: 36, einrichung: 500, mbpreis: 95 },
  { typ: "BLP", laufzeit: 48, einrichung: 500, mbpreis: 90 },
  { typ: "BLP", laufzeit: 60, einrichung: 500, mbpreis: 80 },
  { typ: "WEB", laufzeit: 0, einrichung: 4500, mbpreis: 35 },
  { typ: "SHO", laufzeit: 0, einrichung: 6500, mbpreis: 35 },
  { typ: "HOS", laufzeit: 6, einrichung: 250, mbpreis: 30 },
  { typ: "HOS", laufzeit: 12, einrichung: 250, mbpreis: 30 },
  { typ: "HOS", laufzeit: 24, einrichung: 250, mbpreis: 25 },
  { typ: "HOS", laufzeit: 36, einrichung: 250, mbpreis: 20 },
  { typ: "HOS", laufzeit: 48, einrichung: 250, mbpreis: 20 },
  { typ: "HOS", laufzeit: 60, einrichung: 250, mbpreis: 20 },
  { typ: "SMM", laufzeit: 0, einrichung: 500, mbpreis: 50 },
  { typ: "ADW", laufzeit: 6, einrichung: 250, mbpreis: 200 },
  { typ: "ADW", laufzeit: 12, einrichung: 250, mbpreis: 200 },
  { typ: "ADW", laufzeit: 24, einrichung: 250, mbpreis: 180 },
  { typ: "ADW", laufzeit: 36, einrichung: 250, mbpreis: 160 },
  { typ: "ADW", laufzeit: 48, einrichung: 250, mbpreis: 150 },
  { typ: "ADW", laufzeit: 60, einrichung: 250, mbpreis: 140 },
  { typ: "BUR", laufzeit: 0, einrichung: 3500, mbpreis: 50 },
  { typ: "CNT", laufzeit: 0, einrichung: 200, mbpreis: 50 },
  { typ: "LP1", laufzeit: 0, einrichung: 2500, mbpreis: 50 },
  { typ: "MLP", laufzeit: 0, einrichung: 3500, mbpreis: 50 },
  { typ: "LAW", laufzeit: 0, einrichung: 995, mbpreis: 50 },
  { typ: "PRB", laufzeit: 0, einrichung: 600, mbpreis: 100 },
  { typ: "RTA", laufzeit: 0, einrichung: 600, mbpreis: 100 },
  { typ: "GMB", laufzeit: 0, einrichung: 995, mbpreis: 50 },
  { typ: "YTB", laufzeit: 0, einrichung: 800, mbpreis: 200 },
  { typ: "BTR", laufzeit: 0, einrichung: 350, mbpreis: 150 },
  { typ: "FOT", laufzeit: 0, einrichung: 2900, mbpreis: 20 },
  { typ: "SUP", laufzeit: 0, einrichung: 200, mbpreis: 200 },
  { typ: "YP1", laufzeit: 0, einrichung: 5000, mbpreis: 200 },
  { typ: "YP2", laufzeit: 0, einrichung: 5000, mbpreis: 200 },
  { typ: "YP3", laufzeit: 0, einrichung: 5000, mbpreis: 200 },
  { typ: "YP4", laufzeit: 0, einrichung: 5000, mbpreis: 200 },
  { typ: "PRSM1", laufzeit: 0, einrichung: 250, mbpreis: 200 },
  { typ: "PRSM2", laufzeit: 0, einrichung: 250, mbpreis: 300 },
  { typ: "PRSM3", laufzeit: 0, einrichung: 250, mbpreis: 400 },
  { typ: "PRSM4", laufzeit: 0, einrichung: 500, mbpreis: 500 },
  { typ: "PRSM5", laufzeit: 0, einrichung: 500, mbpreis: 600 },
];
