import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Server } from "react-feather";
import { SY365GlobalVars } from "../../config";
import { getSysMsg } from "../../_apis/chat";
import { Android } from "@mui/icons-material";
import { deepOrange } from "@mui/material/colors";
import { VsIndZeit } from "../visiqsCustomStuff/ProfilIcons";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({
  title,
  description,
  Icon,
  message_id,
}: {
  title: any;
  description: string;
  Icon: React.ElementType;
  message_id: string;
}) {
  return (
    <ListItem
      divider
      component={Link}
      to="/Messaging"
      key={"sysmessID-" + message_id}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: deepOrange[500] }}>
          <Android />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [sysmsgCnt, setsysmsgCnt] = useState(0);
  const [SysMessages, setSysMessages] = React.useState<any[]>([]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setsysmsgCnt(SY365GlobalVars.SysMessages);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    async function blabla() {
      let xxSysMessages = await getSysMsg();
      setSysMessages(xxSysMessages);
      SY365GlobalVars.SysMessages = xxSysMessages.length;
      setsysmsgCnt(xxSysMessages.length);
    }
    blabla();
  }, [isOpen]);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={sysmsgCnt} showZero={false}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {sysmsgCnt} neue Infos
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {SysMessages.map((n) => (
              <Notification
                title={VsIndZeit(n.nachricht_date)}
                description={n.nachricht}
                message_id={n.message_id}
                key={"key2sysmsg" + n.message_id}
                Icon={Server}
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/Messaging">
              Alle Benachtichtigungen
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
