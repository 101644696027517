import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { SessionState } from "sip.js";

type CallIconProps = {
  hangup: () => void;
  call: () => void;
  answer: () => void;
  incoming: boolean;
  inCall: SessionState;
};

const CallIcons = (props: CallIconProps) => {
  // if (inCall) {
  // }

  if (
    props.inCall === SessionState.Established ||
    props.inCall === SessionState.Establishing
  ) {
    return (
      <CallEndIcon
        onClick={props.hangup}
        style={{ cursor: "pointer", color: "red", fontSize: "40px" }}
      />
    );
  }
  if (props.incoming)
    return (
      <CallIcon
        onClick={props.answer}
        style={{ cursor: "pointer", color: "green", fontSize: "40px" }}
      />
    );
  return (
    <CallIcon
      onClick={props.call}
      style={{ cursor: "pointer", color: "green", fontSize: "40px" }}
    />
  );
};

export default CallIcons;
