import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import $ from "jquery";

type AnrufNotizProps = {
  funcs: [boolean, Dispatch<SetStateAction<boolean>>];
  onConfirm: (notiz: string, checked: boolean) => void;
};

const AnrufNotiz = (props: AnrufNotizProps) => {
  const [open, setOpen] = props.funcs;
  const [notiz, setNotiz] = useState("");
  const [checked, setChecked] = useState(false);
  const handleNotizChange = (e: any) => {
    setNotiz(e.target.value);
  };
  const handleClose = () => {
    toast.error("Pflichtangabe. Bitte eine kurze Notiz hinzufügen.");
    // setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Notiz zum Anruf hinzufügen
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bitte hier eine kurze Notiz zum Anruf hinzufügen. Achtung, bei aktiven
          Kunden, kann diese Nachricht auch im Kundencenter öffentlich
          dargestellt sein!
        </DialogContentText>
        <TextField
          autoComplete="off"
          value={notiz}
          fullWidth
          id="notizfeld"
          onChange={handleNotizChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e: any) => setChecked(e.target.checked)}
            />
          }
          label="Zu dieser Notiz ein internes Ticket erstellen?"
        />
        <br />

        <Button
          onClick={() => {
            $("#notizfeld").val("Nicht erreicht");
            setNotiz("Nicht erreicht");
          }}
          size="small"
          color="info"
          style={{ margin: "0px 3px" }}
        >
          Nicht Erreicht
        </Button>
        <Button
          onClick={() => {
            $("#notizfeld").val("Beratungsgespräch");
            setNotiz("Beratungsgespräch");
          }}
          size="small"
          color="info"
          style={{ margin: "0px 3px" }}
        >
          Beratung
        </Button>
        <Button
          onClick={() => {
            $("#notizfeld").val("Aktuell kein Bedarf");
            setNotiz("Aktuell kein Bedarf");
          }}
          size="small"
          color="info"
          style={{ margin: "0px 3px" }}
        >
          Kein Interesse
        </Button>
        <Button
          onClick={() => {
            $("#notizfeld").val("Angebot nachgefasst und Besprochen");
            setNotiz("Angebot nachgefasst und Besprochen");
          }}
          size="small"
          color="info"
          style={{ margin: "0px 3px" }}
        >
          Angebot nachgefasst
        </Button>
        <Button
          onClick={() => {
            $("#notizfeld").val("Support/Beschwerde bearbeitet wegen: ");
            setNotiz("Support/Beschwerde bearbeitet wegen: ");
          }}
          size="small"
          color="info"
          style={{ margin: "0px 3px" }}
        >
          Support
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button
          onClick={() => {
            props.onConfirm(notiz, checked);
            setNotiz("");
          }}
          autoFocus
        >
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnrufNotiz;
