import { xxTest } from "./api";

async function getInbox() {
  const xx = await xxTest().get(`/start/inbox/`);
  const json = xx.data;
  return json;
}
async function getShoutbox() {
  const xx = await xxTest().get(`/start/shoutbox/`);
  const json = xx.data;
  return json;
}

async function getTodos(personal_id: any) {
  const xx = await xxTest().get(`/start/todos/${personal_id}`);
  const json = xx.data;
  return json;
}
async function getCntKvVertrieb() {
  const xx = await xxTest().get(`/start/getCntKvVertrieb/`);
  const json = xx.data;
  return json;
}
async function getCntKvIT() {
  const xx = await xxTest().get(`/start/getCntKvIT/`);
  const json = xx.data;
  return json;
}
async function getCntKVI() {
  const xx = await xxTest().get(`/start/getCntKVI/`);
  const json = xx.data;
  return json;
}

async function gettaskProjektsList() {
  const xx = await xxTest().get(`/start/gettaskProjektsList/`);
  const json = xx.data;
  return json;
}

async function postShout(schrei: any) {
  const xx = await xxTest().post(`/start/FusRoDah`, { geschrei: schrei });
  const json = xx.data;
  return json;
}

async function postEditShout(schrei: any) {
  const xx = await xxTest().post(`/start/postEditShout`, {
    geschrei: schrei,
  });
  const json = xx.data;
  return json;
}

async function postDeleteShout(schrei: any) {
  const xx = await xxTest().post(`/start/postDeleteShout`, {
    geschrei: schrei,
  });
  const json = xx.data;
  return json;
}

export {
  getInbox,
  getTodos,
  getShoutbox,
  postShout,
  getCntKvVertrieb,
  getCntKvIT,
  getCntKVI,
  postEditShout,
  postDeleteShout,
  gettaskProjektsList,
};
