import { xxTest } from "./api";

export type Termin = {
  kunde_id: string;
  datum: Date;
  notiz: string;
  terminierer: string;
  pool: string;
};

async function insertTermin(kid: string, termin: Termin) {
  const xx = await xxTest().post(`/termine/${kid}`, { termin: termin });
  const json = xx.data;
  return json;
}
async function insertWV(kid: string, wv: any) {
  const xx = await xxTest().post(`/termine/insertWV/${kid}`, {
    wv: wv,
  });
  const json = xx.data;
  return json;
}

async function getTermineById() {
  const xx = await xxTest().get(`/termine/`);
  const json = xx.data;
  return json;
}

async function bearbeiteTerminById(
  crawlerId: string,
  neuesDatum: Date,
  neueNotiz: string
) {
  const data = { neueNotiz, datum: neuesDatum };
  const xx = await xxTest().post(`/termine/edit/${crawlerId}`, data);
  const json = xx.data;
  return json;
}
async function loescheTerminById(crawlerId: string) {
  const xx = await xxTest().post(`/termine/delete/${crawlerId}`);
  const json = xx.data;
  return json;
}

async function bewerteTerminById(crawlerId: string) {
  const xx = await xxTest().get(`/termine/rate/${crawlerId}`);
  const json = xx.data;
  return json;
}

async function getTerminDetails(terminId: string) {
  const xx = await xxTest().get(`/termine/get/${terminId}`);
  const json = xx.data;
  return json;
}

async function getTerminPool() {
  const xx = await xxTest().get(`/termine/pool/DE`);
  const json = xx.data;
  return json;
}

async function isFree(termin: any) {
  const xx = await xxTest().post(`/termine/isFree/1`, termin);
  const json = xx.data;
  return json;
}

async function uebertrageDatensatz(kundeData: any) {
  const xx = await xxTest().post(`/termine/uebertrageDatensatz/1`, kundeData);
  const json = xx.data;
  return json;
}

async function giveTermin(termin: any) {
  const xx = await xxTest().post(`/termine/give/${termin.termin_id}`, termin);
  const json = xx.data;
  return json;
}

async function verschiebeTermin(termin: any) {
  const xx = await xxTest().post(
    `/termine/verschiebe/${termin.termin_id}`,
    termin
  );
  const json = xx.data;
  return json;
}

async function getBewerteTermin(tId: string, rating: string) {
  const xx = await xxTest().get(`/termine/getBewerteTermin/${tId}/${rating}`);
  const json = xx.data;
  return json;
}

export {
  insertTermin,
  getTermineById,
  bearbeiteTerminById,
  loescheTerminById,
  bewerteTerminById,
  insertWV,
  getTerminDetails,
  getTerminPool,
  isFree,
  giveTermin,
  verschiebeTermin,
  getBewerteTermin,
  uebertrageDatensatz,
};
