import axios from "axios";

const api_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SERVER_URL_DEV_API
    : process.env.REACT_APP_SERVER_URL_LIVE_API;

const apiClient = axios.create({
  baseURL: api_url,
});

function xxTest() {
  const token = localStorage.getItem("accessToken");
  const apiClient = axios.create({
    baseURL: api_url,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return apiClient;
}

export { apiClient, xxTest };
