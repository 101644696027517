import moment from "moment";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SY365GlobalVars } from "../config";
import useAuth from "../hooks/useAuth";
import { getDirectMsg, getSysMsg } from "../_apis/chat";
import { getCntKVI, getCntKvIT, getCntKvVertrieb } from "../_apis/start";

const NotificationChecker365 = () => {
  const { user } = useAuth();

  useEffect(() => {
    const interval = setInterval(async () => {
      let lastcall = moment().diff(SY365GlobalVars.lastUpdateNotificationsCall);
      if (lastcall > 20000 && user?.role != undefined) {
        SY365GlobalVars.lastUpdateNotificationsCall = new Date();
        //Noch blödsinn, querys noch erstellen

        //@todo Querys zusammenfassen?
        let xxMessages = await getDirectMsg();
        let xxSysMessages = await getSysMsg();
        let cntKV_Vertrieb = await getCntKvVertrieb();
        let cntKV_IT = await getCntKvIT();
        let cntKVI = await getCntKVI();
        SY365GlobalVars.Messages = xxMessages.length;
        SY365GlobalVars.SysMessages = xxSysMessages.length;
        SY365GlobalVars.KV_IT = cntKV_IT[0].cntKV_IT;
        SY365GlobalVars.KV_Vertrieb = cntKV_Vertrieb[0].cntKV_Vertrieb;
        SY365GlobalVars.KVI = cntKVI[0].cntKVI;
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default NotificationChecker365;
