import React, { useState } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import { ToastContainer } from "react-toastify";

import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./components/alteReactScheisse/store";

import NeuTelefon from "./components/Telefon365_Components/NeuTelefon";
import TerminReminder from "./components/TerminReminder";
import NotificationChecker365 from "./components/NotificationChecker365";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SY365GlobalVars } from "./config";
import { AuthProvider } from "./components/alteReactScheisse/JWTContext";
import createTheme from "./components/alteReactScheisse/theme";
import moment from "moment";
import Cookies from "universal-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const cookies = new Cookies();
let ys_styleSrc = "";
if (
  moment().format("DD.MM") == "31.10" &&
  cookies.get("YS_HolidayStyle") != "OFF"
) {
  ys_styleSrc = "/static/img/sy-dingen/halloween.css";
}
if (cookies.get("YS_HolidayStyle") == "XXX") {
  ys_styleSrc = "/static/img/sy-dingen/halloween.css";
}

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const [msgCnt, setmsgCnt] = useState("0");

  React.useEffect(() => {
    const interval = setInterval(() => {
      //console.log("inter", SY365GlobalVars);
      setmsgCnt(String(SY365GlobalVars.Messages));
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const content = useRoutes(routes);
  if (localStorage.getItem("LohnEsel") == undefined) {
    localStorage.setItem("LohnEsel", JSON.stringify([]));
  }
  const { theme } = useTheme();

  return (
    <GoogleOAuthProvider clientId="781074046585-piuv1gelb3vrv3ndru9h4e959mei3heu.apps.googleusercontent.com">
      <HelmetProvider>
        <Helmet
          titleTemplate={msgCnt == "0" ? "VMS" : "(" + msgCnt + ") VMS"}
          defaultTitle={msgCnt == "0" ? "VMS" : "(" + msgCnt + ") VMS"}
        />
        <link
          rel="icon"
          href={
            msgCnt == "0"
              ? "/static/img/logo_visiqs_ico.ico"
              : "/static/img/favicon-msg.ico"
          }
        />
        {ys_styleSrc != "" && <link href={ys_styleSrc} rel="stylesheet" />}
        <Provider store={store}>
          {/*
        // @ts-ignore */}
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <ThemeProvider theme={createTheme(theme)}>
                    <AuthProvider>
                      {/*react-router-cache-route ausprobieren?*/ content}
                      <NeuTelefon />
                      <TerminReminder />
                      <NotificationChecker365 />
                      <ToastContainer />
                    </AuthProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </Provider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
