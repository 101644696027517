import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTermineById } from "../_apis/termine";

const TerminReminder = () => {
  const { user } = useAuth();
  const isHeute = (date: any) => {
    const dateToCheck = new Date(date);
    const heute = new Date();
    return (
      dateToCheck.getDate() === heute.getDate() &&
      dateToCheck.getMonth() === heute.getMonth() &&
      dateToCheck.getFullYear() === heute.getFullYear()
    );
  };

  const isGleich = (date: any) => {
    const dateToCheck = new Date(date);
    const jetzt = new Date();

    var diffMs = dateToCheck.getTime() - jetzt.getTime(); // milliseconds between now & Christmas
    var diffMins = Math.round(diffMs / 60000);

    return diffMins;
  };

  const sachen = async () => {
    const termine = await getTermineById();
    for (const termin of termine) {
      if (isHeute(termin.termin_datum)) {
        if (
          isGleich(termin.termin_datum) < 12 &&
          isGleich(termin.termin_datum) > 0
        ) {
          toast(
            `Sie haben einen Termin mit ${termin.firma} in ${Math.round(
              isGleich(termin.termin_datum)
            )} Minuten! `,
            { autoClose: 5000 }
          );

          //hier html5 dingen einmal spielen
          console.log("gleich");
        }

        // console.log(termin);
      }
      //   console.log(termin);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.role != undefined) {
        sachen();
      }
    }, 180000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default TerminReminder;
