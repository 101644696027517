import * as React from "react";
import styled from "styled-components/macro";

import { Tooltip, IconButton as MuiIconButton } from "@mui/material";
import { Phone } from "react-feather";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarTelefon() {
  const hideHasselPho = (e: any) => {
    var diivc = document.getElementById("portal-root");
    if (diivc != null) {
      diivc.style.display = "block";
    }
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={hideHasselPho} size="large">
        <Tooltip title="Telefon">
          <Phone />
        </Tooltip>
      </IconButton>
    </React.Fragment>
  );
}

export default NavbarTelefon;
