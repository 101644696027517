import { xxTest } from "./api";

async function getNewTickets() {
  const xx = await xxTest().get(`/support/getNewTickets/get`);
  const json = xx.data;
  return json;
}

async function createTicket(kunde_id: string, ticket: any) {
  const data = { ticket };
  const xx = await xxTest().post(`/support/createTicket/${kunde_id}`, data);
  const json = xx.data;
  return json;
}

async function getMyTickets() {
  const xx = await xxTest().get(`/support/getMyTickets/get`);
  const json = xx.data;
  return json;
}

async function getTicketInfo(id: any) {
  const xx = await xxTest().get(`/support/getTicket/${id}`);
  const json = xx.data;
  return json;
}
async function getMeineVorlagen() {
  const xx = await xxTest().get(`/support/getMeineVorlagen/1`);
  const json = xx.data;
  return json;
}

async function getTicketsZuKunde(kid: any) {
  const xx = await xxTest().get(`/support/getTicketsZuKunde/${kid}`);
  const json = xx.data;
  return json;
}

async function commentTicket(
  intern: boolean,
  ticket: any,
  antwort: any,
  anhang?: any,
  anhangName?: any
) {
  const data = { ticket, antwort, anhang, anhangName };
  console.log(data);
  const xx = await xxTest().post(`/support/comment/${intern}`, data);
  const json = xx.data;
  return json;
}

async function getTicketComments(id: any) {
  const xx = await xxTest().get(`/support/getCommentsZuTicket/${id}`);
  const json = xx.data;
  return json;
}

async function getUserInfo(id: any) {
  const xx = await xxTest().get(`/support/getUserInfo/${id}`);
  const json = xx.data;
  return json;
}

async function ticketZuweisen(id: any, zuweisungId: any) {
  const data = { id, zuweisungId };
  console.log(data);
  const xx = await xxTest().post(`/support/zuweisung/${id}`, data);
  const json = xx.data;
  return json;
}
async function ticketStatus(id: any, status: any) {
  const data = { id, status };
  console.log(data);
  const xx = await xxTest().post(`/support/status/${id}`, data);
  const json = xx.data;
  return json;
}

async function updateTicket(id: any, data: any) {
  const xx = await xxTest().post(`/support/updateTicket/${id}`, data);
  const json = xx.data;
  return json;
}
async function searchTickets(query: any) {
  const xx = await xxTest().get(`/support/search/${query}`);
  const json = xx.data;
  return json;
}

async function neuesTicket(data: any) {
  const ticket = data;
  const xx = await xxTest().post(`/support/newTicket`, { ticket: data });
  const json = xx.data;
  return json;
}

async function addCC(ticketId: any, ccMail: any) {
  const xx = await xxTest().post(`/support/addCC/${ticketId}`, {
    mail: ccMail,
  });
  const json = xx.data;
  return json;
}
async function delCC(data: any) {
  console.log("delllll");
  const xx = await xxTest().post(`/support/delCC`, data);
  const json = xx.data;
  return json;
}
async function changeRequester(
  ticketId: any,
  ccMail: any,
  name: any,
  zenUId: any
) {
  const xx = await xxTest().post(`/support/changeRequester/${ticketId}`, {
    mail: ccMail,
    name: name,
    zenUId: zenUId,
  });
  const json = xx.data;
  return json;
}
async function searchYandeskUser(query: any) {
  const xx = await xxTest().post(`/support/searchYandeskUser/1`, {
    query: query,
  });
  const json = xx.data;
  return json;
}

export {
  getNewTickets,
  createTicket,
  getMyTickets,
  getTicketInfo,
  getTicketsZuKunde,
  commentTicket,
  getTicketComments,
  getUserInfo,
  // ticketZuweisen,
  // ticketStatus,
  updateTicket,
  searchTickets,
  neuesTicket,
  addCC,
  delCC,
  getMeineVorlagen,
  changeRequester,
  searchYandeskUser,
};
