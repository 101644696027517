import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";
import { MessageSquare } from "react-feather";

import { SY365GlobalVars } from "../../config";
import { VsIndZeit, ProfilPicVsSys } from "../visiqsCustomStuff/ProfilIcons";
import { getDirectMsg } from "../../_apis/chat";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Message({
  title,
  description,
  personal_id,
  name_betrieb,
  message_id,
}: {
  title: string;
  description: string;
  personal_id: string;
  name_betrieb: string;
  message_id: string;
}) {
  return (
    <ListItem
      divider
      component={Link}
      to="/Messaging"
      key={"messID-" + message_id}
    >
      <ListItemAvatar>
        {ProfilPicVsSys(personal_id, name_betrieb, "Navbar")}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarMessagesDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [msgCnt, setmsgCnt] = useState(0);
  const [Messages, setMessages] = React.useState<any[]>([]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setmsgCnt(SY365GlobalVars.Messages);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    async function blabla() {
      let xxMessages = await getDirectMsg();
      setMessages(xxMessages);
      SY365GlobalVars.Messages = xxMessages.length;
      setmsgCnt(xxMessages.length);
    }
    blabla();
  }, [isOpen]);

  return (
    <React.Fragment>
      <Tooltip title="Messages">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={msgCnt} showZero={false}>
            <MessageSquare />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {msgCnt} neue Nachrichten
          </Typography>
        </MessageHeader>
        <React.Fragment>
          <List disablePadding>
            {Messages.map((n) => (
              <Message
                title={n.name_betrieb}
                description={n.nachricht}
                personal_id={n.absender_id}
                name_betrieb={n.name_betrieb}
                message_id={n.message_id}
                key={"2keymessid-" + n.message_id}
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/Messaging">
              Alle Nachrichten
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarMessagesDropdown;
