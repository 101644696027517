import { createContext, ReactNode, useEffect, useReducer } from "react";
import { apiClient, xxTest } from "../../_apis/api";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "./auth";

import axios from "../../utils/axios";
import { isValidToken, setSession, verify } from "../../utils/jwt";
import { getLohneselListe } from "../../_apis/personal";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const secret = process.env.REACT_APP_JWT_SECRET || "";
          const data: any = verify(accessToken, secret);
          const userId = typeof data === "object" ? data?.id : "";
          const response = await xxTest().get(`/personal/${userId}`);
          const user = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (id: string, password: string, fa2: any) => {
    const response = await apiClient.post(`/auth/login`, {
      id: id,
      password,
      fa2: fa2,
    });
    const { accessToken } = response.data;
    setSession(accessToken);

    const Realresponse = await xxTest().get(`/personal/${id}`);
    const user = Realresponse.data;

    const personen = await getLohneselListe();
    localStorage.setItem("LohnEsel", JSON.stringify(personen));
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const fa2check = async (id: string, password: string) => {
    const check2FA = await apiClient.post(`/auth/check2FA`, {
      id: id,
      password,
    });

    return check2FA.data;
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        fa2check,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
