import React, { useRef, useState } from "react";
import styled from "styled-components";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Popover as MuiPopover,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import { toast } from "react-toastify";
import { taetigkeit } from "../../_apis/personal";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function NavbarZeiterfassung() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [minuten, setMinuten] = React.useState("0");
  const handleChangeMinuten = (event: SelectChangeEvent) => {
    setMinuten(event.target.value);
  };
  const [thema, setThemaAuswahl] = React.useState("PAU");
  const handleChangeThema = (event: SelectChangeEvent) => {
    setThemaAuswahl(event.target.value);
  };
  const [tätigkeit, settätigkeit] = React.useState("");

  async function postTätigkeit() {
    const xx = await taetigkeit({
      aenderung: tätigkeit,
      minuten: minuten,
      thema: thema,
    });
    if (xx === "OK") {
      toast.success("Erfasst");
      setOpen(false);
    } else toast.error("Fehler beim Speichern");
  }

  return (
    <React.Fragment>
      <Tooltip title="Zeiterfassung">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <AccessTime />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            Zeiterfassung
          </Typography>
        </NotificationHeader>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="demo-simple-select-standard-label"
            label=""
            id="demo-simple-select-standard"
            value={thema}
            onChange={handleChangeThema}
            style={{ margin: "3px 0px" }}
          >
            <MenuItem value="PAU">Pause</MenuItem>
            <MenuItem value="INT">Intern</MenuItem>
            <MenuItem value="WEB">Webdesign</MenuItem>
            <MenuItem value="TXT">Texte/Redaktion</MenuItem>
          </Select>

          <Select
            labelId="demo-simple-select-standard-label2"
            id="demo-simple-select-standard2"
            value={minuten}
            label=""
            onChange={handleChangeMinuten}
            style={{ margin: "3px 0px" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="0">0 Minuten</MenuItem>
            <MenuItem value="2">2 Minuten</MenuItem>
            <MenuItem value="5">5 Minuten</MenuItem>
            <MenuItem value="10">10 Minuten</MenuItem>
            <MenuItem value="15">15 Minuten</MenuItem>
            <MenuItem value="20">20 Minuten</MenuItem>
            <MenuItem value="25">25 Minuten</MenuItem>
            <MenuItem value="30">30 Minuten</MenuItem>
            <MenuItem value="45">45 Minuten</MenuItem>
            <MenuItem value="60">60 Minuten</MenuItem>
          </Select>
          <TextField
            id="standard-basic"
            label=""
            variant="outlined"
            placeholder="Tätigkeit"
            onChange={(e) => {
              settätigkeit(e.target.value);
            }}
            style={{ margin: "3px 0px" }}
            multiline
            rows={4}
          />
          <Button
            variant="outlined"
            onClick={() => {
              postTätigkeit();
            }}
          >
            Erfassen
          </Button>
        </FormControl>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarZeiterfassung;
