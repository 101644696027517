import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { SidebarItemsType } from "../alteReactScheisse/sidebar";
import useAuth from "../../hooks/useAuth";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: rgb(33, 43, 54);
  background-color: rgb(249, 250, 251);
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  const { user } = useAuth();
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" className="ys_logo  ys_des">
        {/* <BrandIcon />{" "} */}
        <Box ml={1}>
          {user?.standort == "MO" && (
            <img
              src="/static/img/sy-dingen/sls-logo-big.png"
              height="60px"
              alt=""
            />
          )}
          {user?.standort == "BO" && (
            <img
              src="/static/img/sy-dingen/logo_visiqs_big.png"
              height="80px"
              className="ys_logo"
              alt=""
            />
          )}
        </Box>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
