import { xxTest } from "./api";

async function getVertriebler() {
  const xx = await xxTest().get(`/personal/vertriebler/get`);
  const json = xx.data;
  return json;
}
async function getVertrieblerStammdaten() {
  const xx = await xxTest().get(`/personal/getVertrieblerStammdaten/get`);
  const json = xx.data;
  return json;
}
async function getLohneselListe() {
  const xx = await xxTest().get(`/personal/lohneselliste/get`);
  const json = xx.data;
  return json;
}

async function getITMitarbeiter() {
  const xx = await xxTest().get(`/personal/itMitarbeiter/get`);
  const json = xx.data;
  return json;
}
async function getVertriebsKollegen() {
  const xx = await xxTest().get(`/personal/getVertriebsKollegen/get`);
  const json = xx.data;
  return json;
}

async function getLaender() {
  const xx = await xxTest().get(`/personal/getLaender/get`);
  const json = xx.data;
  return json;
}

async function taetigkeit(data: any) {
  const xx = await xxTest().post(`/personal/erfasseTaetigkeit`, data);
  const json = xx.data;
  return json;
}

async function uploadProfilPic(data: any) {
  const xx = await xxTest().post(`/personal/uploadProfilPic`, data);
  const json = xx.data;
  return json;
}

async function getPersTasks() {
  const xx = await xxTest().get(`/personal/getPersTasks/get`);
  const json = xx.data;
  return json;
}

async function getDritteTasks() {
  const xx = await xxTest().get(`/personal/getDritteTasks/get`);
  const json = xx.data;
  return json;
}

async function getPersonalInfo2(personal_id: any) {
  const xx = await xxTest().post(`/personal/getPersonalInfo2/get`, {
    personal_id,
  });
  const json = xx.data;
  return json;
}

async function postNewTaskRep(data: any) {
  const xx = await xxTest().post(`/personal/postNewTaskRep/get`, data);
  const json = xx.data;
  return json;
}
async function postDelTaskRepId(data: any) {
  const xx = await xxTest().post(`/personal/postDelTaskRepId/get`, data);
  const json = xx.data;
  return json;
}

async function getAbonierteTaskKats() {
  const xx = await xxTest().get(`/personal/getAbonierteTaskKats/get`);
  const json = xx.data;
  return json;
}

async function getTaskPlaner() {
  const xx = await xxTest().get(`/personal/getTaskPlaner/get`);
  const json = xx.data;
  return json;
}

async function postSetTaskErledigt(data: any) {
  const xx = await xxTest().post(`/personal/postSetTaskErledigt/get`, data);
  const json = xx.data;
  return json;
}
async function postSetTaskUNErledigt(data: any) {
  const xx = await xxTest().post(`/personal/postSetTaskUNErledigt/get`, data);
  const json = xx.data;
  return json;
}
async function postNeuerEinzelTask(data: any) {
  const xx = await xxTest().post(`/personal/postNeuerEinzelTask/get`, data);
  const json = xx.data;
  return json;
}
async function postGetTaskPerTaskId(data: any) {
  const xx = await xxTest().post(`/personal/postGetTaskPerTaskId/get`, data);
  const json = xx.data;
  return json;
}

async function postNeuerTaskKommentar(data: any) {
  const xx = await xxTest().post(`/personal/postNeuerTaskKommentar/get`, data);
  const json = xx.data;
  return json;
}

async function postUpdateTaskInfos(data: any) {
  const xx = await xxTest().post(`/personal/postUpdateTaskInfos/get`, data);
  const json = xx.data;
  return json;
}
async function postGetTaskKommentare(data: any) {
  const xx = await xxTest().post(`/personal/postGetTaskKommentare/get`, data);
  const json = xx.data;
  return json;
}

async function getBearbeiterInfo(kunde_id: any) {
  const xx = await xxTest().get(`/personal/getBearbeiterInfo/${kunde_id}`);
  const json = xx.data;
  return json;
}
async function getUrlaubUndKrankListe() {
  const xx = await xxTest().get(`/personal/getUrlaubUndKrankListe/get`);
  const json = xx.data;
  return json;
}

async function postNewPassword(data: any) {
  const xx = await xxTest().post(`/personal/postNewPassword/`, data);
  const json = xx.data;
  return json;
}
async function getMe() {
  const xx = await xxTest().get(`/personal/getMe`);
  const json = xx.data;
  return json;
}

async function generate2FA() {
  const xx = await xxTest().get(`/personal/generate2FA/get`);
  const json = xx.data;
  return json;
}
async function activate2FA(data: any) {
  const xx = await xxTest().post(`/personal/activate2FA/`, data);
  const json = xx.data;
  return json;
}

export {
  getUrlaubUndKrankListe,
  getPersonalInfo2,
  getDritteTasks,
  getVertriebler,
  getLohneselListe,
  getITMitarbeiter,
  taetigkeit,
  getVertriebsKollegen,
  getLaender,
  getVertrieblerStammdaten,
  uploadProfilPic,
  getPersTasks,
  getAbonierteTaskKats,
  getTaskPlaner,
  postNewTaskRep,
  postDelTaskRepId,
  postSetTaskErledigt,
  postSetTaskUNErledigt,
  postNeuerEinzelTask,
  postGetTaskPerTaskId,
  postNeuerTaskKommentar,
  postUpdateTaskInfos,
  postGetTaskKommentare,
  getBearbeiterInfo,
  postNewPassword,
  getMe,
  generate2FA,
  activate2FA,
};
